// src/components/DrawButton.js
import React, { useContext } from 'react';
import { GiftExchangeContext } from '../context/GiftExchangeContext';
import { Button } from '@mui/material';
import useSound from 'use-sound';
import drawSound from '../sounds/draw_sound.mp3';
import { useTranslation } from 'react-i18next';

function DrawButton() {
  const { drawGift } = useContext(GiftExchangeContext);
  const [play] = useSound(drawSound);
  const { t } = useTranslation();

  const handleClick = () => {
    play();
    drawGift();
  };

  return (
    <Button 
      variant="contained" 
      color="primary" 
      onClick={handleClick}
      sx={{ fontSize: '1.2rem', padding: '10px 20px' }}
    >
      {t('drawGift')}
    </Button>
  );
}

export default DrawButton;
