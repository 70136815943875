// src/firebase.js
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAny-yTNy-QVC1wa5UJXu95NKUB1MIETkM",
  authDomain: "christmas-gift-exchange.firebaseapp.com",
  projectId: "christmas-gift-exchange",
  storageBucket: "christmas-gift-exchange.appspot.com",
  messagingSenderId: "76001104628",
  appId: "1:76001104628:web:691e363eac93681272c4a1",
  measurementId: "G-EN5D1FX53E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Analytics only if supported
isSupported().then((isSupported) => {
  if (isSupported) {
      // eslint-disable-next-line no-unused-vars
      const analytics = getAnalytics(app);
  } else {
      console.log("Firebase Analytics not supported in this environment");
  }
});

