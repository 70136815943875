// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json'; // English translations
import translationZH from './locales/zh/translation.json'; // Chinese translations

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      zh: { translation: translationZH },
    },
    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: { escapeValue: false },
  });

export default i18n;
