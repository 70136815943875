// src/context/GiftExchangeContext.js
import React, { createContext, useState } from 'react';

export const GiftExchangeContext = createContext();

export const GiftExchangeProvider = ({ children }) => {
  const [participants, setParticipants] = useState(null);
  const [drawnGifts, setDrawnGifts] = useState([]);
  const [lastDrawnGift, setLastDrawnGift] = useState(null);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [drawnGiftsLog, setDrawnGiftsLog] = useState([]);

  const initializeGiftExchange = (participantCount) => {
    setTotalParticipants(participantCount);
    setParticipants(Array.from({ length: participantCount }, (_, i) => i + 1));
    setDrawnGifts(Array.from({ length: participantCount }, (_, i) => i + 1).sort(() => Math.random() - 0.5));
  };

  const drawGift = () => {
    console.log("Drawing a gift. Current drawnGifts:", drawnGifts);
    if (participants.length === 1 && drawnGifts.length === 0) {
      setLastDrawnGift(participants[0]);
      setParticipants([]);
      console.log(`Drawn gift for last participant: ${participants[0]}`);
      return;
    }
  
    if (drawnGifts.length > 0) {
      const nextDraw = drawnGifts[0];
      setLastDrawnGift(nextDraw);
      setParticipants(currentParticipants => 
        currentParticipants.filter(p => p !== nextDraw));
      setDrawnGifts(currentDrawnGifts => currentDrawnGifts.slice(1));
      setDrawnGiftsLog(currentLog => [...currentLog, nextDraw]); // Update the drawn gifts log
      console.log(`Drawn gift: ${nextDraw}`);
    }
  };
  
  const drawGiftAgain = () => {
    console.log("Drawing gift again. Participants:", participants);
    if (participants.length > 0) {
      const shuffled = [lastDrawnGift, ...drawnGifts.sort(() => Math.random() - 0.5)];
      const nextDraw = shuffled[0];
      setLastDrawnGift(nextDraw);
      setDrawnGifts(shuffled.slice(1));
      console.log(`Redrawn gift: ${nextDraw}`);
    }
  };

  const restartGiftExchange = () => {
    setParticipants(null);
    setDrawnGifts([]);
    setLastDrawnGift(null);
    setTotalParticipants(0);
    setDrawnGiftsLog([])
  };

  return (
    <GiftExchangeContext.Provider value={{
      participants,
      drawnGifts,
      lastDrawnGift,
      totalParticipants,
      initializeGiftExchange,
      drawGift,
      drawGiftAgain,
      restartGiftExchange,
      drawnGiftsLog
    }}>
      {children}
    </GiftExchangeContext.Provider>
  );
};
