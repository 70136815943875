// src/components/GiftExchange.js
import React, { useContext, useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import NumberInputForm from './NumberInputForm';
import DrawButton from './DrawButton';
import ConfettiAnimation from './ConfettiAnimation';
import { GiftExchangeContext } from '../context/GiftExchangeContext';
import { Box, Typography, Button, Stack, IconButton, CssBaseline, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

function GiftExchange() {
  const {
    participants,
    lastDrawnGift,
    drawnGifts,
    totalParticipants,
    restartGiftExchange,
    drawGiftAgain,
    drawnGiftsLog
  } = useContext(GiftExchangeContext);
  const [showAllDrawn, setShowAllDrawn] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("Current participants:", participants);
    console.log("Current drawnGifts:", drawnGifts);
    console.log("Current showAllDrawn state:", showAllDrawn);
  }, [participants, drawnGifts, showAllDrawn]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(newLang);
  };

  const handleRestart = () => {
    restartGiftExchange();
  };

  const handleDrawAgain = () => {
    drawGiftAgain();
  };

  const toggleShowAllDrawn = () => {
    setShowAllDrawn(!showAllDrawn);
    console.log("Toggling showAllDrawn state to", !showAllDrawn);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ position: 'fixed', top: 0, right: 0, m: 2, zIndex: 1000 }}>
        <IconButton onClick={toggleLanguage}>
          <LanguageIcon />
        </IconButton>
      </Box>
      <Box sx={{ textAlign: 'center', pt: 3 }}>
        <div style={{ overflow: 'hidden', position: 'absolute', left: '-9999px' }}>
          <YouTube videoId="xbb1OxLkGCg" opts={{
            height: '1',
            width: '1',
            playerVars: {
              autoplay: 1,
              controls: 0,
              loop: 1,
              playlist: 'xbb1OxLkGCg',
              mute: 0,
              origin: window.location.origin
            }
          }} />
        </div>
        <Typography variant="h3" sx={{ mb: 2 }}>{t('giftExchangeTitle')}</Typography>
        {!participants && (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>{t('startInstructions')}</Typography>
            <NumberInputForm />
          </>
        )}
        {participants && participants.length > 0 && (
          <>
            {lastDrawnGift !== null && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" component="span">
                  {t('drawnGiftNumber', { number: totalParticipants - participants.length })}
                </Typography>
                <Typography variant="h2" component="span" sx={{ fontSize: '5rem', color: 'secondary.main', display: 'block' }}>
                  {lastDrawnGift}
                </Typography>
              </Box>
            )}
              <Stack direction="column" alignItems="center" spacing={4}>
                <DrawButton sx={{ fontSize: '1.2rem', padding: '10px 20px' }} />
                <Button variant="contained" color="secondary" onClick={handleDrawAgain} sx={{ fontSize: '1.2rem', padding: '10px 20px' }}>
                  {t('drawAgain')}
                </Button>
              </Stack>
          </>
        )}
        {participants && participants.length === 0 && (
          <>
            <ConfettiAnimation />
            <Typography variant="h6" sx={{ mt: 2 }}>
              {t('finalGiftDrawn')}
            </Typography>
            <Typography variant="h2" sx={{ fontSize: '5rem', color: 'secondary.main', display: 'block' }}>
              {lastDrawnGift}
            </Typography>
            <Stack direction="column" alignItems="center" spacing={4}>
              <Button variant="contained" color="primary" sx={{ mt: 3, fontSize: '1.2rem', padding: '10px 20px' }} onClick={handleRestart}>
                {t('restart')}
              </Button>
              <Button variant="contained" color="secondary" sx={{ mt: 3, fontSize: '1.2rem', padding: '10px 20px' }} onClick={toggleShowAllDrawn}>
                {t('showAllRecords')}
              </Button>
              {showAllDrawn && (
                <Paper elevation={3} sx={{ 
                  mt: 3, 
                  p: 2, 
                  backgroundColor: 'background.paper', 
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'scroll'
                }}>
                  <List>
                    {drawnGiftsLog.map((gift, index) => (
                      <ListItem key={index} sx={{ border: 1, borderColor: 'divider', my: 1, borderRadius: 'borderRadius' }}>
                        <ListItemText primary={`${index + 1}. ${t('gift', { number: gift })}`} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </Stack>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}

export default GiftExchange;