// src/components/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GiftExchange from './GiftExchange';
import { GiftExchangeProvider } from '../context/GiftExchangeContext';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n'; // Assuming you have an i18n configuration file

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff1744',
    },
    secondary: {
      main: '#2979ff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <GiftExchangeProvider>
          <Router>
            <Routes>
              <Route path="/" element={<GiftExchange />} />
              <Route path="/en" element={<GiftExchange />} />
            </Routes>
          </Router>
        </GiftExchangeProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
